import React from 'react'

import { Title, Text } from 'components/Landings/BusinessOrPleasure'

const FriendshipRelocations = () => {
  return (
    <div className='tc mb7vh center'>
      <div className='w-80 center mv6'>
        <div className='dib-l w-90 w-40-l v-mid ph4-m ph5-l center'>
          <Title black className='b'>
            Road Trips
          </Title>

          <Text black className='mt4 lh-copy'>
            Hitting the road? Staying with friends and friends-of-friends along
            the way will make all the difference.
          </Text>
        </div>

        <figure className='dib-l w-90 w-40-l v-mid ma0 center'>
          <img
            alt='Comfort of friends'
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509389671/assets/Road_Trip_900_pjshru.gif'
            className='pa2 pa4-l ba b--light-gray w-100 shadow-4'
          />

          <Text black as='figcaption' className='ph5 mt4 lh-copy'>
            The comfort of friends: the road tripper's secret weapon.
          </Text>
        </figure>
      </div>

      <div className='w-80 center mv6'>
        <figure className='dn dib-l w-90 w-40-l v-mid ma0 center'>
          <img
            alt='Friends on a world map.'
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509389669/assets/World_Travel_900_b6vc3e.gif'
            className='pa2 pa4-l ba b--light-gray w-100 shadow-4'
          />

          <Text black as='figcaption' className='ph5 mt4 lh-copy'>
            All your friends and friends-of-friends on a world map. The world is
            yours.
          </Text>
        </figure>

        <div className='dib-l w-90 w-40-l v-mid ph4-m ph5-l center'>
          <Title black className='b'>
            World Trips
          </Title>

          <Text black className='mt4 lh-copy'>
            Taking the time to travel the world is the best decision you ever
            made.
          </Text>
        </div>

        <figure className='w-90 w-40-l v-mid ma0 center db dn-l'>
          <img
            alt='Friends on a world map.'
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509389669/assets/World_Travel_900_b6vc3e.gif'
            className='pa2 pa4-l ba b--light-gray w-100 shadow-4'
          />

          <Text black as='figcaption' className='mt4 lh-copy'>
            All your friends and friends-of-friends on a world map? The world is
            yours.
          </Text>
        </figure>
      </div>
    </div>
  )
}

export default FriendshipRelocations
