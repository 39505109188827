import React from 'react'

import colors from 'styles/colors'

import { SectionHeadline } from 'components/Landings/Hero'
import SignUpButton from 'components/Landings/SignUpButton'
import {
  Logo,
  Row,
  TopRow,
  RowHeader,
  RowCell,
  HeaderArrow,
  CheckmarkIcon,
  CrossIcon
} from 'components/Landings/ComparedTable'

const FriendshipCompared = () => {
  return (
    <div className='bg-white mt4 mt6-ns'>
      <SectionHeadline className='ph3 tc'>
        Friendship Stays:{' '}
        <span style={{ color: colors.darkBlue }}>Compared.</span>
      </SectionHeadline>

      <TopRow className='mt5'>
        <RowHeader className='pa3-ns' />

        <RowCell gray>
          <span>Hotels</span>
        </RowCell>
        <RowCell gray className='pa1 pa3-ns tc'>
          <span>
            Other <span className='dn di-ns'>homesharing</span> platforms
          </span>
        </RowCell>
        <RowCell className='tc ph2 pa3-ns' bg={colors.darkBlue}>
          <Logo />
        </RowCell>
      </TopRow>

      <TopRow className='lh-solid'>
        <RowHeader className='pa3-ns' />

        <HeaderArrow />
        <HeaderArrow />
        <HeaderArrow blue />
      </TopRow>

      <Row gray>
        <RowHeader>
          <span className='b'>Cost </span>
          per night.
        </RowHeader>

        <RowCell>
          <span>$55</span>
        </RowCell>
        <RowCell>
          <span>$55</span>
        </RowCell>
        <RowCell>
          <span>$0</span>
        </RowCell>
      </Row>

      <Row>
        <RowHeader>
          Stay with <span className='b'>friend you know and trust.</span>
        </RowHeader>

        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row gray>
        <RowHeader>
          Stay with a friend-of-friend{' '}
          <span className='b'>through someone you know and trust.</span>
        </RowHeader>

        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row>
        <RowHeader>
          <span className='b'>Map of the world</span> with{' '}
          <span className='b'>
            all your friends and friends-of-friends on it.
          </span>
        </RowHeader>

        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CrossIcon />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row gray>
        <RowHeader>
          Platform <span className='b'>fees</span>
        </RowHeader>

        <RowCell>
          <span>?</span>
        </RowCell>
        <RowCell>
          <span>$19</span>
        </RowCell>
        <RowCell>
          <span>$0</span>
        </RowCell>
      </Row>

      <div className='tc'>
        <SignUpButton
          className='mt3 mt5-ns ph5 b'
          showLogin={false}
          showIfLoggedIn={false}
          style={{ width: 'auto', display: 'inline-block' }}
        />
      </div>
    </div>
  )
}

export default FriendshipCompared
