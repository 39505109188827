import React from 'react'

import Helmet from 'react-helmet'

import * as routes from 'config/routes'

import styled from 'styled-components'

import Layout from 'components/Layout'

import {
  HeroContainer,
  MiddleContainer,
  Headline,
  SubHeadline
} from 'components/Landings/Hero'
import VideoView from 'components/Landings/VideoView'
import SignUpButton from 'components/Landings/SignUpButton'
import ButtonLink from 'components/Landings/ButtonLink'
import LineBreak from 'components/Landings/LineBreak'

import FriendshipRelocations from './components/_FriendshipRelocations'
import FriendshipStay from './components/_FriendshipStay'
import FriendshipCards from './components/_FriendshipCards'
import FriendshipPersonas from './components/_FriendshipPersonas'
import FriendshipCompared from './components/_FriendshipCompared'

import { MapView } from '../home'
import Privacy from 'components/Landings/Privacy'
import SectionsView from 'components/Landings/SectionsView'
import Footer from 'components/Landings/Footer'
import InvitationBadge from 'components/Landings/InvitationBadge'

const FriendshipPage = () => (
  <Layout className='overflow-x-hidden'>
    <Helmet>
      <title>Friendship Stays</title>

      <meta
        name='description'
        content='Book rooms with friends and friends-of-friends. Stay for free and share your lives together.'
      />
      <meta
        name='image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
      />

      <meta itemProp='name' content='Friendship Stays - StayCircles' />
      <meta
        itemProp='description'
        content='Book rooms with friends and friends-of-friends. Stay for free and share your lives together.'
      />
      <meta
        itemProp='image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
      />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content='Friendship Stays - StayCircles' />
      <meta
        name='twitter:description'
        content='Book rooms with friends and friends-of-friends. Stay for free and share your lives together.'
      />

      <meta name='og:title' content='Friendship Stays - StayCircles' />
      <meta
        name='og:description'
        content='Book rooms with friends and friends-of-friends. Stay for free and share your lives together.'
      />
      <meta
        name='og:image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542078445/assets/meta/Friendship.png'
      />
      <meta name='og:url' content='https://www.staycircles.com/friendship' />
      <meta name='og:site_name' content='StayCircles' />
      <meta name='og:type' content='website' />
    </Helmet>

    <Hero />

    <FriendshipStay />
    <FriendshipCards />
    <LineBreak />

    <FriendshipPersonas personas={['jetsetter', 'overlander']} />
    <LineBreak />

    <FriendshipCompared />
    <LineBreak />

    <FriendshipPersonas personas={['aventurer', 'weekender']} />
    <LineBreak />

    <FriendshipRelocations />
    <MapView />
    <Privacy />

    <SectionsView sections={['business', 'adventure']} />

    <Footer createAccount marginTop />

    <InvitationBadge />
  </Layout>
)

export const Hero = ({ friendshipPage = true }) => {
  return (
    <Container hideBackground={!friendshipPage}>
      <div className='h-100 ph3 ph4-l'>
        <Headline fw={friendshipPage ? 500 : 300}>Friendship Stays</Headline>

        <SubHeadline>
          Book stays with friends and <br />
          friends-of-friends.
        </SubHeadline>

        <MiddleContainer className='w-100 flex flex-row justify-around items-center'>
          <ChartImage
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_mpad,w_500/assets/globe.png'
            className='dn db-l'
          />

          <VideoView videoUrl='https://www.youtube.com/embed/hWS7XmmDSAk?rel=0&autoplay=1'>
            {({ onClick }) => (
              <img
                src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1518804970/assets/Video_-_Laura.png'
                onClick={onClick}
                className='w-60 w-auto-l h-100-l pointer'
                title='Meet Laura'
                alt='Meet Laura'
              />
            )}
          </VideoView>

          <ChartImage
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_crop,w_1860/c_scale,w_500/assets/hangout_i3gbth.png'
            className='dn db-l'
          />
        </MiddleContainer>

        <SubHeadline>
          Stay for free and <br className='dn db-l' />
          share happy moments together.
        </SubHeadline>

        <MainButton friendshipPage={friendshipPage} />
      </div>
    </Container>
  )
}

const Container = styled(HeroContainer)`
  text-align: center;

  @media (max-width: 344px) {
    height: 95vh;
  }

  @media (min-width: 345px) and (max-width: 767px) {
    height: 90vh;
  }
`

const ChartImage = styled.img`
  width: 16%;

  @media (min-width: 768px) {
    width: 20%;
  }
`

const MainButton = ({ friendshipPage }) => {
  if (friendshipPage) return <SignUpButton />

  return (
    <ButtonLink to={routes.preserveQuery(routes.FRIENDSHIP)}>
      Explore Friendship Stays
    </ButtonLink>
  )
}

export default FriendshipPage
