import React from 'react'

import styled from 'styled-components'

export const CardsContainer = styled.div.attrs({
  className: 'mt4 flex center justify-center flex-wrap'
})`
  width: 100vw;
  overflow-x: scroll;
  max-width: 1470px;
  margin-bottom: 6.6vh;
  flex-direction: row;

  @media (max-width: 1120px) {
    justify-content: left;
    flex-wrap: nowrap;
  }

  @media (min-width: 1920px) {
    max-width: 76.5vw;
  }

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &[class~='compact'] {
    @media (min-width: 1121px) {
      max-width: 740px;
    }

    @media (min-width: 1920px) {
      max-width: 45vw;
    }
  }
`

export const CardItem = ({
  text,
  textProps = {},
  img,
  imgs,
  className = ''
}) => (
  <CardView className={`ba br3 dib shadow-4 b--light-gray ${className}`}>
    <p
      className='ph4 mb0 mt3 flex flex-row justify-center items-center'
      {...textProps}
    >
      <span>{text}</span>
    </p>

    {img && <img {...img} />}

    {imgs && (
      <div className='w-100 flex justify-center items-center'>
        {imgs.map(imgi => (
          <img key={imgi.src} {...imgi} />
        ))}
      </div>
    )}
  </CardView>
)

const CardView = styled.div`
  width: 280px;
  height: 280px;
  padding: 1rem;
  margin: 0.5rem;
  box-sizing: border-box;
  flex: 0 0 auto;

  @media (min-width: 400px) {
    width: 350px;
    height: 280px;
  }

  @media (min-width: 1920px) {
    width: 20vw;
    height: 32vh;
    margin: 1.1vh 0.55vw;
    padding: 1.1vh 0.55vw;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    height: 30%;

    @media (min-width: 320px) {
      font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
    }

    @media (min-width: 1920px) {
      font-size: 2.1vmin;
    }
  }

  img {
    max-height: 60%;
  }
`
