import React from 'react'

import styled from 'styled-components'

import { Text } from 'components/Landings/BusinessOrPleasure'

const personasMap = {
  jetsetter: () => <Jetsetter />,
  overlander: () => <Overlander />,
  aventurer: () => <Aventurer />,
  weekender: () => <Weekender />
}

const FriendshipPersonas = ({ personas = [] }) => {
  if (!personas.every(key => !!personasMap[key])) {
    throw new Error(
      '`personas` array contain invalid items. Valid items are: \n' +
        Object.keys(personasMap).join(', ')
    )
  }

  return (
    <div className='tc'>
      {personas.map(key => {
        const PersonaComponent = personasMap[key]

        if (!PersonaComponent) return null

        return <PersonaComponent key={key} />
      })}
    </div>
  )
}

const Jetsetter = () => (
  <PersonaContainer className='dt dib-l w-100 w-40-l center ph4'>
    <div className='dtc v-mid dib-l w-50'>
      <img
        alt='Jetsetter'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509397421/assets/jetsetter_kphaej.png'
        className='w-80 w-100-l'
      />
    </div>

    <Text black fw={300} className='lh-copy dtc v-mid dib-l w-50 w-40-l mb0'>
      Last minute weekend in Ireland to celebrate a birthday? <br />
      <span className='b'>Yes.</span>
      <br />
      StayCircles map is full of friends and friends-of-friends to grab a
      celebratory beer with?
      <br />
      <span className='b'>Yes.</span>
      <br />
      This is the way you live, and StayCircles helps make it more awesome.
    </Text>
  </PersonaContainer>
)

const Overlander = () => (
  <PersonaContainer className='dt dib-l w-100 w-40-l center ph4'>
    <div className='dtc v-mid dn-l w-50'>
      <img
        alt='Overlander'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509397421/assets/overlander_pkbz9d.png'
        className='w-80 w-50-l'
      />
    </div>

    <Text black fw={300} className='lh-copy dtc v-mid dib-l w-50 w-40-l mb0'>
      The world is your playground and adventures are your game.
      <br />
      Gear up.
      <br />
      Fill up.
      <br />
      <span className='b'>Explore.</span>
      <br />
      Along the way meeting friends-of-friends to show you the local hidden
      spots?
      <br />
      <span className='b'>Yes.</span>
    </Text>

    <div className='dn dib-l v-mid w-50'>
      <img
        alt='Overlander'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509397421/assets/overlander_pkbz9d.png'
        className='w-80 w-100-l'
      />
    </div>
  </PersonaContainer>
)

const Aventurer = () => (
  <PersonaContainer className='dt dib-l w-100 w-40-l center ph4'>
    <div className='dtc v-mid dib-l w-50'>
      <img
        alt='Aventurer'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509395573/assets/adventurer_vqto3g.png'
        className='w-80 w-100-l'
      />
    </div>

    <Text black fw={300} className='lh-copy dtc v-mid dib-l w-50 w-40-l mb0'>
      A month backpacking Thailand? <br />
      <span className='b'>Yes.</span>
      <br />
      Go anywhere, check-out-anything-cool attitude?
      <br />
      <span className='b'>Yes.</span>
      <br />
      The world is yours. Get on the map, and see who is waiting for you.
    </Text>
  </PersonaContainer>
)

const Weekender = () => (
  <PersonaContainer className='dt dib-l w-100 w-40-l center ph4'>
    <div className='dtc v-mid dn-l w-50'>
      <img
        alt='Weekender'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509395582/assets/weekender_gdik0q.png'
        className='w-80 w-50-l'
      />
    </div>

    <Text black fw={300} className='lh-copy dtc v-mid dib-l w-50 w-40-l mb0'>
      A month backpacking Thailand?
      <br />
      Grab the train to NYC for the weekend?
      <br />
      <span className='b'>Yes.</span>
      <br />
      Book a stay with a friend on the way?
      <br />
      <span className='b'>Yes.</span>
      <br />
      The StayCircles life.
    </Text>

    <WeekenderImageWrapper className='dn dib-l v-mid w-50'>
      <img
        alt='Weekender'
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1509395582/assets/weekender_gdik0q.png'
        className='w-80 w-100-l'
      />
    </WeekenderImageWrapper>
  </PersonaContainer>
)

const PersonaContainer = styled.div`
  position: relative;
  font-size: 2rem;

  @media (max-width: 960px) {
    &:not(:first-child)::after {
      content: '';
      height: 1px;
      width: 60%;
      margin: 60px 20%;
      display: table-caption;
      border-bottom: 1px solid #ccc;
    }

    &:last-child {
      margin-bottom: 30px;
    }

    p {
      width: 50%;
    }
  }

  @media (min-width: 961px) {
    &:not(:first-child)::before {
      content: ' ';
      height: 60%;
      width: 1px;
      position: absolute;
      top: 20%;
      left: 0;
      background-color: #bdbdbd;
    }
  }
`

const WeekenderImageWrapper = styled.div`
  @media (min-width: 961px) {
    width: 56%;
  }
`

export default FriendshipPersonas
