import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { SectionHeadline, SubHeadline } from 'components/Landings/Hero'

const Text = styled(SubHeadline)`
  font-weight: 300;
  text-align: center;
  margin-bottom: 26px;

  @media (min-width: 1024px) {
    &&& {
      font-size: 26px;
    }
  }

  > sup {
    font-weight: 700;
    color: ${colors.darkBlue};
  }
`

const Privacy = () => (
  <div className='tc pv5'>
    <SectionHeadline className='ph3'>Serious privacy, simply.</SectionHeadline>

    <div className='flex flex-column flex-row-l justify-center items-center mv4 pv4'>
      <div className='w-100 w-50-l ph4 mb5 mb0-l flex flex-column flex-row-l justify-end items-center'>
        <img
          alt=''
          src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_240/assets/Privacy_StayPals.png'
          className='dib dn-l mb4'
        />

        <div>
          <Text>
            Friend are, <br className='dn db-l' />
            those who you care for and enjoy <br className='dn db-l' />
            spending time with.
          </Text>

          <Text>You share your address with Friends.</Text>
        </div>

        <img
          alt=''
          src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_240/assets/Privacy_StayPals.png'
          className='dn dib-l ml4-l'
        />
      </div>

      <div className='h4 w1 bw2 bl b--near-black dn db-l' />

      <div className='w-100 w-50-l ph4 flex flex-column flex-row-l justify-start items-center'>
        <img
          alt=''
          src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_240/assets/Privacy_StayPals2.png'
          className='mr4-l mb4 mb0-l'
        />

        <div>
          <Text>
            Friends-of-Friends
            {/* <sup>2</sup> are good friends of good
            <br className="dn db-l" /> friends. */}
          </Text>

          <Text>
            You share your home’s general <br className='dn db-l' />
            location with Friends of Friends.
          </Text>
        </div>
      </div>
    </div>

    <div className='w-80 center'>
      <Text>
        StayCircles is a technology platform for you to build and enjoy you
        personal <br className='dn db-l' />
        network of close friends. It was built to make it easier for you to
        spend time with <br className='dn db-l' />
        your people.
      </Text>

      <Text>Your trust is our honor.</Text>
    </div>
  </div>
)

export default Privacy
