import React from 'react'

import styled from 'styled-components'

import { Text } from 'components/Landings/BusinessOrPleasure'

const FriendshipStay = () => (
  <Container className='cover flex items-center'>
    <InnerContainer className='white w-80 w-60-m w-25-l tc ml6-l center'>
      <Title className='mb4'>Friendship Stay</Title>

      <Text className='lh-copy'>
        Friendship Stays make finding a place to stay with your friends or a
        friend-of-a-friend easy and organized.
        <br /> <br /> And, it’s completely free. Forever.
      </Text>
    </InnerContainer>
  </Container>
)

const Container = styled.div`
  background: url('https://res.cloudinary.com/staycircles/image/upload/v1507940499/assets/friendship_stays_cover.jpg')
    center;
  height: 70vh;

  @media (max-width: 900px) {
    background: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best/v1507943468/assets/business_bg_small.jpg')
      center no-repeat;
    height: 70vh;
  }
`

const InnerContainer = styled.div`
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 80%;
    margin: 0 auto;
  }
`

const Title = styled.h1`
  font-size: 2.1vw;
  font-weight: 600;

  @media (max-width: 950px) {
    font-size: 22px;
  }
`

export default FriendshipStay
