import React from 'react'

import styled from 'styled-components'

import { SectionHeadline } from 'components/Landings/Hero'
import { CardsContainer, CardItem } from 'components/Landings/Cards'
import SignUpButton from 'components/Landings/SignUpButton'

const BusinessCards = () => {
  return (
    <Container className='tc'>
      <SectionHeadline className='ph3'>
        Friendship Stays: How it works
      </SectionHeadline>

      <CardsContainer>
        <CardItem
          text='There’s a show you really want to see in the city this weekend, so you check out StayCircles to see who you know.'
          img={{
            src:
              'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_500/v1508743391/assets/travel_city',
            alt: 'Checkout StayCircles to see who you know'
          }}
        />

        <CardItem
          text='You book a stay with your friend (or a friend-of-a-friend) and you’re on your way.'
          img={{
            src:
              'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_500/v1508743451/assets/free_stay',
            alt: 'You book a stay with your friend'
          }}
        />

        <CardItem
          text='You enjoy a free place to stay and a chance to catch up with a friend (or make a new one). Invite your friend along to the show!'
          img={{
            src:
              'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_500/v1508743304/assets/visit_friend',
            alt: 'You enjoy a free place to stay'
          }}
        />
      </CardsContainer>

      <div className='center'>
        <SignUpButton
          className='mt4 ph5 b'
          showLogin={false}
          showIfLoggedIn={false}
          style={{ width: 'auto', display: 'inline-block' }}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 13.2vh;
  margin-bottom: 13.2vh;
`

export default BusinessCards
